html,
body {
  height: 100%;
}

router-outlet {
  display: none;
}

:is(button, [role='button']):not([disabled]) {
  cursor: pointer;
}
