@keyframes ldio-ring {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-ring {
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  transform-origin: 0 0;
  transform: translateZ(0) scale(1);
  width: 100%;

  div {
    animation: ldio-ring 1s linear infinite;
    border-radius: 50%;
    border-top-color: transparent;
    border: 10px solid #3f51b5;
    box-sizing: content-box;
    height: 70px;
    left: 100px;
    position: absolute;
    top: 100px;
    width: 70px;
  }
}

.loadingio-spinner-rolling-1vrmof83pbn {
  background: #ffffff;
  display: inline-block;
  height: 200px;
  overflow: hidden;
  width: 200px;
}
