.p-tab-view--full-height {
  .p-tabview {
    height: 100%;
    display: grid;
    grid-template:
      'tabs' auto
      'panels' 1fr / 100%;
  }

  .p-tabview-panel {
    height: 100%;
  }
}
