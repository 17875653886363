.p-card--grow-content {
  .p-card {
    height: 100%;
  }

  .p-card-body {
    display: grid;
    height: 100%;
    grid-template-rows: 1fr auto;
  }
}

.p-card--no-elevation {
  .p-card {
    box-shadow: none;
    border: 1px solid hsl(0, 0%, 90%);
  }
}

.p-card--no-border {
  .p-card {
    box-shadow: none;
    border: 0;
  }
}
