@use '@angular/material';
@use 'flag-icons/css/flag-icons.min.css';

:root {
  --pwp-color-primary: var(--primary-color);
  --pwp-color-error: hsl(345, 100%, 38%);
  --pwp-color-warning: hsl(45, 100%, 50%);
  --pwp-color-success: hsl(150, 98%, 35%);
  --pwp-color-background: hsl(213, 39%, 95%);
  --pwp-color-white: hsl(0, 0%, 100%);
  --pwp-color-black: hsl(0, 0%, 0%);
}

$pwp-primary: material.define-palette(
  (
    100: var(--primary-100),
    200: var(--primary-200),
    300: var(--primary-300),
    400: var(--primary-400),
    500: var(--primary-500),
    600: var(--primary-600),
    700: var(--primary-700),
    800: var(--primary-800),
    900: var(--primary-900),
    contrast: (
      100: var(--pwp-color-black),
      200: var(--pwp-color-black),
      300: var(--pwp-color-black),
      400: var(--pwp-color-black),
      500: var(--pwp-color-white),
      600: var(--pwp-color-white),
      700: var(--pwp-color-white),
      800: var(--pwp-color-white),
      900: var(--pwp-color-white),
    ),
  )
);

$pwp-accent: material.define-palette(
  (
    100: var(--bluegray-100),
    200: var(--bluegray-200),
    300: var(--bluegray-300),
    400: var(--bluegray-400),
    500: var(--bluegray-500),
    600: var(--bluegray-600),
    700: var(--bluegray-700),
    800: var(--bluegray-800),
    900: var(--bluegray-900),
    contrast: (
      100: var(--pwp-color-black),
      200: var(--pwp-color-black),
      300: var(--pwp-color-black),
      400: var(--pwp-color-black),
      500: var(--pwp-color-white),
      600: var(--pwp-color-white),
      700: var(--pwp-color-white),
      800: var(--pwp-color-white),
      900: var(--pwp-color-white),
    ),
  )
);

$light-theme: material.define-light-theme(
  (
    color: (
      primary: $pwp-primary,
      accent: $pwp-accent,
    ),
  )
);

@include material.legacy-core();
@include material.all-legacy-component-typographies(material.define-legacy-typography-config());
@include material.all-legacy-component-themes($light-theme);

survey-creator {
  --primary: var(--primary-500);
  --primary-light: var(--primary-100);
  --foreground: var(--pwp-color-black);
  --primary-foreground: var(--pwp-color-white);
  --secondary: var(--gray-500);
}
