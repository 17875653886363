/* NOTE: organization settings are within a PrimeNG table cell that sets the white space settings as default, which often breaks responsiveness */
.p-element .p-datatable-resizable .p-datatable-tbody > tr > td {
  white-space: unset;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-radius: 4px 4px 0 0;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-radius: 0 0 4px 4px;
}
